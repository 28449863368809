import { css } from '@emotion/react';

export const middleTitle = css`
  font-size: 1.25em;
  line-height: 1.35;
  letter-spacing: 0.05em;
  font-weight: 700;
  color: #35187e;
  margin: 60px 0 0;
  text-align: center;

  &.c-white {
    color: #fff;
  }
  @media (max-width: 767px) {
    font-size: 1.1em;
    margin: 25px 0 0;
  }
`;

export const scheduleDl = css`
  text-align: center;

  & + & {
    margin-top: 3rem;
  }
  > dt {
    font-size: 1.15em;
    letter-spacing: 0.05em;
    font-weight: 700;
    margin-right: 20px;
    display: inline-block;
    margin: 0 0 15px;
    > span {
      display: block;
      padding: 5px 11px;
      border: 2px solid #233333;
    }
  }
  > dd {
    font-size: 1.25em;
    line-height: 1.35;
    letter-spacing: 0.05em;
    font-weight: 700;
    padding: 6px 0 0;

    & + dd {
      margin-top: 25px;
    }

    > span {
      font-size: 0.8em;
      line-height: 1.5;
      margin-top: 10px;
      display: block;
    }
  }
  &.c-white {
    > dt,
    > dd {
      color: #fff;
    }
    > dt {
      > span {
        border-color: #fff;
      }
    }
  }

  .gatsby-image-wrapper-constrained {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    text-align: center;
    display: block;
    > dt {
      font-size: 0.9em;
      margin: 0 0 7px;
      display: inline-block;
      > span {
        padding: 3px 6px;
        border: 1px solid #233333;
      }
    }

    > dd {
      display: block;
      font-size: 1em;
    }
  }
`;
export const creditList = css`
  & + & {
    margin-top: 35px;
  }
  dt,
  dd {
    text-align: center;
  }
  dt {
    font-size: 1em;
    line-height: 1.35;
    letter-spacing: 0.05em;
    font-weight: 700;
    text-align: center;
  }

  dd {
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: 0.05em;
    margin-top: 10px;
  }

  @media (max-width: 767px) {
    dt {
      font-size: 1em;
    }
    dd {
      font-size: 1em;
      margin-top: 10px;
    }

    & + & {
      margin-top: 30px;
    }
  }
`;
export const platform = css`
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  .row {
    display: flex;
    margin: 45px -10px 0;
    .col {
      width: 100%;
      display: block;
      text-align: center;
      padding: 0 10px;
      .platform-title {
        font-size: 1em;
        line-height: 1.35;
        letter-spacing: 0.05em;
        font-weight: 700;
        color: #fff;
        margin: 0 0 15px;
      }
      .image-wrap {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 220px;
        background: #fff;
        height: 80px;
        border-radius: 7px;
        img {
          max-width: 100%;
          width: auto;
          height: 28px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    a {
      .platform-logo,
      .gatsby-image-wrapper {
        transition: all 0.3s ease;
      }
    }

    a:hover {
      .platform-logo,
      .gatsby-image-wrapper {
        transform: scale(1.08);
      }
    }
  }

  @media (max-width: 767px) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    .row {
      margin: 28px 0 0;
      .col {
        padding: 0 5px;
        .platform-title {
          font-size: 0.9em;
          margin: 0 0 10px;
        }
        .image-wrap {
          min-width: inherit;
          height: auto;
          padding: 20px 0;
          width: 100%;
          max-width: 200px;
          box-sizing: border-box;
          height: 62px;
          img {
            height: 24px;
          }
        }
      }
    }
  }
`;

export const aboutText = css`
  font-size: 1.15em;
  line-height: 1.75;
  position: relative;
  z-index: 1;
  strong {
    font-weight: 700;
  }

  @media (max-width: 767px) {
    font-size: 1em;
    line-height: 1.5;
  }
`;

export const aboutSec = css`
  overflow: hidden;
  .container {
    position: relative;
    .gatsby-image-wrapper {
      position: absolute;
      top: 95px;
      right: -25px;
      margin: auto;
      z-index: 0;
      opacity: 0.2;
    }
  }

  @media (max-width: 767px) {
    .container {
      .gatsby-image-wrapper {
        max-width: 257px;
        top: 165px;
        right: 16px;
        opacity: 0.2;
      }
    }
  }

  @media (max-width: 400px) {
    .container {
      .gatsby-image-wrapper {
        max-width: 200px;
        top: 320px;
      }
    }
  }
`;

export const tickectSection = css`
  margin-top: 4.5rem;
  @media (max-width: 768px) {
    margin-top: 2.5rem;
  }
`;

export const mw600 = css`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

export const mw800 = css`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

export const mw1024 = css`
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
`;

export const eventSchedule = css`
  padding: 2.5rem calc(2.5rem - 12px);
  border: 3px solid #f2875b;
  background: #fff;
  margin-top: 1.1rem;

  .section_title {
    font-size: 1.15em;
    line-height: 1.35;
    letter-spacing: 0.05em;
    font-weight: 700;
    color: #3b3b3b;
    margin: 0 0 18px;
    text-align: center;

    &:not(:first-of-type) {
      margin-top: 60px;
    }

    @media (max-width: 767px) {
      font-size: 1.1em;
      margin: 25px 0 0;
    }
  }

  .event-schedule-date {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 700;
    color: #3b3b3b;
  }

  > ul {
    text-align: center;
    > li {
      & + li {
        margin-top: 1rem;
      }
      &:not(.event-schedule-date) + li {
        margin-top: 2.5rem;
      }
    }

    dl {
      dt {
        color: #f15b85;
        font-weight: 700;
        margin: 0 0 0.3rem;

        .schedule {
          margin-top: 5px;
        }
      }

      .member_image {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
      }

      .dl-flex {
        font-size: 0.875rem;
        display: flex;
        margin-top: 10px;
        padding: 0 8px;

        &.no-padding {
          padding: 0;
        }

        &.sp-direction-column {
          @media (max-width: 767px) {
            flex-direction: column;
          }
        }

        > .flex-heading {
          font-weight: bold;
          min-width: 3rem;
        }
        &.flex-center {
          justify-content: center;
        }
        &.flex-left {
          justify-content: start;
        }
        .flex-cell-caution {
          font-size: 0.75rem;
          margin-top: 0.5rem;
          text-indent: -1.25rem;
          padding-left: 1.25rem;
          .flex-cell {
            display: inline;
            > li {
              display: inline;
            }
          }
        }
        .flex-cell,
        > ul {
          > li {
            display: inline-block;
            &::after {
              content: '/';
              padding-left: 0.25em;
              padding-right: 0.25em;
            }

            &:last-of-type {
              &::after {
                content: '';
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }
      }
      .ticket-schedule {
        font-weight: bold;
        margin-bottom: 0.25rem;
      }
      .caution-small {
        font-weight: normal;
        font-size: 0.85rem;
        margin-left: 1rem;

        @media (max-width: 767px) {
          display: block;
          margin-left: 0;
          margin-top: 5px;
        }
      }

      .image-wrap {
        margin-top: 1.5rem;
      }

      ol {
        list-style: decimal;
        text-align: left;
        margin-left: 1.25rem;
        font-weight: bold;
        li + li {
          margin-top: 0.5rem;
        }
      }

      .top-list {
        font-weight: bold;
        & + .top-list {
          margin-top: 0.75rem;
        }
        > li + li {
          margin-top: 0.75rem;
        }
        > ul,
        > ol {
          > li + li {
            margin-top: 0.5rem;
          }
        }
      }

      .caution-list {
        font-size: 0.75rem;
        font-weight: normal;
        > li {
          text-indent: -0.75rem;
          padding-left: 0.75rem;
        }
        > li + li {
          margin-top: 0.25rem;
        }
      }

      a {
        transition: 0.3s ease;
        @media (min-width: 769px) {
          &:hover {
            opacity: 0.75;
          }
        }
      }

      .caution {
        font-size: 0.85rem;
        margin-top: 6px;
        font-weight: normal;
        margin-bottom: 0;
      }
      .disc-decimal {
        list-style: none;
        margin-left: 0;
        font-weight: normal;
        margin-top: 0.5rem;
        > li {
          display: flex;
        }
        strong {
          display: block;
          margin-right: 0.25rem;
        }
      }

      .benefits-title {
        margin-top: 14px;
        margin-bottom: 6px;
        font-size: 14px;
      }
      .benefits-list {
        li {
          font-size: 14px;
          line-height: 1.5;
          text-indent: -1em;
          padding-left: 1em;

          & + li {
            margin-top: 6px;
          }
        }

        & + .caution {
          font-size: 13px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 1.1rem;

    .event-schedule-date {
      font-size: 14px;
    }

    .section_title {
      margin-bottom: 16px;
    }

    > ul {
      > li {
        & + li {
          margin-top: 0.9rem;
        }

        .image-wrap {
          margin-top: 1rem;
        }
      }

      dl {
        .member_image {
          margin-top: 0.8rem;
        }
      }
    }
  }

  @media (min-width: 768px) {
    a {
      .gatsby-image-wrapper {
        transition: opacity 0.3s ease;
      }

      &:hover {
        .gatsby-image-wrapper {
          opacity: 0.75;
        }
      }
    }
  }
`;

export const timetableImage = css`
  margin: 0 0 60px;
  @media (max-width: 767px) {
    margin: 0 0 20px;
  }

  @media (min-width: 768px) {
    a {
      .gatsby-image-wrapper {
        transition: opacity 0.3s ease;
      }

      &:hover {
        .gatsby-image-wrapper {
          opacity: 0.75;
        }
      }
    }
  }
`;

export const eventScheduleBoard = css`
  margin-bottom: 3rem;
`;

export const ticketSchedule = css`
  ${eventSchedule}

  > ul {
    > li {
      & + li {
        margin-top: 1.5rem;
      }
    }
    dl {
      > dt,
      > dd {
        display: inline-block;
      }

      > dt {
        margin-right: 1rem;
      }

      @media (max-width: 767px) {
        > dt,
        > dd {
          display: block;
        }
      }
    }
  }
`;

export const modalAreaStyle = css`
  .row + .row {
    padding-top: 75px;
    @media (max-width: 767px) {
      padding-top: 32px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -15px -40px;
    @media (max-width: 767px) {
      margin: 0 -８px -16px;
    }
    > .col {
      width: 100%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      padding: 0 15px 40px;

      @media (min-width: 768px) {
        &:nth-of-type(1),
        &:nth-of-type(3),
        &:nth-of-type(6),
        &:nth-of-type(8),
        &:nth-of-type(9),
        &:nth-of-type(11),
        &:nth-of-type(14),
        &:nth-of-type(16),
        &:nth-of-type(17),
        &:nth-of-type(19) {
          .modal_button {
            background-color: #ffc815;
          }
        }
      }

      @media (max-width: 767px) {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 8px 16px;

        &:nth-of-type(1),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(8),
        &:nth-of-type(9),
        &:nth-of-type(12),
        &:nth-of-type(13),
        &:nth-of-type(16),
        &:nth-of-type(17),
        &:nth-of-type(20) {
          .modal_button {
            background-color: #ffc815;
          }
        }
      }
    }
  }

  .container + .container {
    margin-top: 60px;
  }

  .modal-button-wrap {
    height: 100%;
    .image-wrap {
      overflow: hidden;

      @media (max-width: 1000px) and (min-width: 768px) {
        height: 70.5%;
      }
    }
  }

  .modal_button {
    background-color: #ffd447;
    display: block;
    height: 100%;
    cursor: pointer;
  }

  .member-name-wrap {
    min-height: 80px;
    padding: 10px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1000px) and (min-width: 768px) {
      height: 29.5%;
    }
    .member-name {
      letter-spacing: 0.001em;
      font-weight: 700;
      text-align: center;
      small {
        font-size: 0.75em;
        text-align: center;
        display: block;
        margin-top: 0.3rem;
      }
    }
  }

  .other_member {
    margin-top: 4.5rem;
    @media (max-width: 768px) {
      margin-top: 3.5rem;
    }
  }

  @media (min-width: 768px) {
    .modal_button {
      .image-wrap {
        overflow: hidden;
        margin-top: 0;
        img {
          transition: all 0.3s ease;
        }
      }
    }
    .modal_button {
      &:hover {
        .image-wrap {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
`;

export const otherSecStyle = css`
  .goods-link {
    transition: opacity 0.3s ease;
    display: block;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;

    .gatsby-image-wrapper {
      transition: opacity 0.3s ease;
    }

    h3 {
      transition: opacity 0.3s ease;
    }

    @media (min-width: 769px) {
      &:hover {
        .gatsby-image-wrapper {
          opacity: 0.75;
        }

        h3 {
          opacity: 0.75;
        }
      }
    }
  }

  .other-wrap {
    &.banner-wrap {
      padding: 2.5rem;
      border: 3px solid #cd8f32;
      background: #fff;
      margin-top: 1.1rem;
      max-width: 1024px;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 768px) {
        padding: 1.1rem;
      }

      > div[data-gatsby-image-wrapper] {
        margin-bottom: 1.5rem;
        display: block;
      }
    }

    .banner-wrapper {
      display: block;
    }

    h3 {
      font-size: 1.25em;
      font-weight: bold;
      color: #233333;
    }

    a {
      transition: 0.3s ease;
      display: inline-block;
      @media (min-width: 769px) {
        &:hover {
          opacity: 0.75;
        }
      }
    }

    & > dl {
      margin-top: 1rem;
      dt {
        font-size: 1.25em;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
      dd {
        margin-top: 10px;

        .stamprally-attention {
          list-style: '※';
          padding-left: 1em;
          font-size: 0.8rem;
        }

        .stamprally-shop {
          max-width: 400px;
          margin: 0 auto;
          padding-left: 1em;
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          text-align: left;
          list-style: '◆';
        }

        .stamprally-present {
          width: fit-content;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: left;
        }

        .stamprally-shop-attention,
        .stamprally-present-attention {
          width: fit-content;
          margin: 8px auto 0;
          text-align: left;
          list-style: '※';
          padding-left: 1em;
          font-size: 0.8rem;
      }
    }
    & + .other-wrap {
      margin-top: 4rem;
    }

    @media (max-width: 768px) {
      .banner-wrapper {
        margin-bottom: 1.5rem;
      }

      & + .other-wrap {
        margin-top: 2.7rem;
      }
    }
  }

  .dl-flex {
    font-size: 0.875rem;
    display: flex;
    margin-top: 10px;
    padding: 0 8px;
    > .flex-heading {
      font-weight: bold;
      min-width: 6rem;

      &.min-small {
        min-width: 2rem;
      }
    }
    &.flex-center {
      justify-content: center;
    }
    &.flex-left {
      justify-content: start;
    }
    &.no-padding {
      padding: 0;
    }
    &.sp-direction-column {
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    .flex-cell-caution {
      font-size: 0.75rem;
      margin-top: 0.5rem;
      text-indent: -0.8rem;
      padding-left: 0.8rem;
      .flex-cell {
        display: inline;
        > li {
          display: inline;
        }
      }
    }
    .flex-cell,
    > ul {
      > li {
        display: inline-block;
        &::after {
          content: '/';
          padding-left: 0.25em;
          padding-right: 0.25em;
        }

        &:last-of-type {
          &::after {
            content: '';
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
`;

export const listLineup = css`
  margin: 0 0 3rem;

  li {
    text-align: center;
  }

  a {
    display: inline-block;
    border-radius: 16px;
    box-shadow: 4px 2px 23px -12px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    @media (min-width: 769px) {
      &:hover {
        transform: scale(0.98);
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
      }
    }

    p {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 2rem;

    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const buttonTop = css`
  margin: 15px -10px 0;
  display: flex;
  justify-content: center;

  > li {
    padding: 0 10px;
  }

  @media (max-width: 767px) {
    margin-top: 10px;
  }
`;

export const caution = css`
  font-size: 0.8rem;
  font-weight: 700;
  color: #fff;
  text-indent: -0.8rem;
  margin: 1rem 0 0 0.8rem;
  padding-left: -0.8rem;
  box-sizing: border-box;
`;

export const imageMap = css`
  position: relative;
  display: block;
  z-index: 1;

  @media (max-width: 767px) {
    display: none;
  }

  .gatsby-image-wrapper {
    max-width: 100%;
  }

  .link-target {
    background: transparent;
    transition: all 0.3s ease;
    border-radius: 15px;
    @media (min-width: 769px) {
      &:hover {
        background: rgba(236, 143, 188, 0.75);
      }
    }
  }

  .gareria {
    position: absolute;
    top: 22vw;
    left: 54vw;
    z-index: 2;
    display: inline-block;
    width: 13vw;
    height: 13vw;

    @media (min-width: 1440px) {
      top: 317px;
      left: 778px;
      width: 187px;
      height: 187px;
    }
  }

  .kireimo {
    position: absolute;
    top: 42.5vw;
    left: 72.5vw;
    z-index: 2;
    display: inline-block;
    width: 13vw;
    height: 13vw;

    @media (min-width: 1440px) {
      top: 617px;
      left: 1053px;
      width: 179px;
      height: 176px;
    }
  }

  .tower {
    position: absolute;
    top: 8.5vw;
    left: 35.5vw;
    z-index: 1;
    display: inline-block;
    width: 29vw;
    height: 18vw;
    @media (min-width: 1440px) {
      top: 119px;
      left: 509px;
      width: 419px;
      height: 252px;
    }
  }

  .youtube {
    position: absolute;
    top: 5.5vw;
    left: 1.5vw;
    z-index: 2;
    display: inline-block;
    width: 29vw;
    height: 18vw;
    @media (min-width: 1440px) {
      top: 81px;
      left: 59px;
      width: 379px;
      height: 215px;
    }
  }

  .goods {
    position: absolute;
    top: 29.5vw;
    left: 19.5vw;
    z-index: 1;
    display: inline-block;
    width: 15vw;
    height: 13vw;

    @media (min-width: 1440px) {
      top: 430px;
      left: 280px;
      width: 222px;
      height: 220px;
    }
  }

  .koeste {
    position: absolute;
    top: 42.75vw;
    left: 14vw;
    z-index: 1;
    display: inline-block;
    width: 12vw;
    height: 12vw;
    @media (min-width: 1440px) {
      top: 616px;
      left: 205px;
      width: 168px;
      height: 168px;
    }
  }

  .lll_cafe {
    position: absolute;
    top: 27vw;
    left: 67vw;
    z-index: 1;
    display: inline-block;
    width: 32vw;
    height: 14vw;
    @media (min-width: 1440px) {
      top: 387px;
      left: 966px;
      width: 464px;
      height: 206px;
    }
  }

  .shabe_fes {
    position: absolute;
    top: 5vw;
    left: 67.5vw;
    z-index: 1;
    display: inline-block;
    width: 28vw;
    height: 16vw;

    @media (min-width: 1440px) {
      top: 70px;
      left: 986px;
      width: 392px;
      height: 224px;
    }
  }

  .vtrip {
    position: absolute;
    top: 22vw;
    left: 32vw;
    z-index: 1;
    display: inline-block;
    width: 14vw;
    height: 14vw;
    @media (min-width: 1440px) {
      top: 317px;
      left: 461px;
      width: 202px;
      height: 202px;
    }
  }

  .showroom {
    position: absolute;
    top: 25vw;
    left: 10vw;
    z-index: 2;
    display: inline-block;
    width: 11.5vw;
    height: 9vw;
    @media (min-width: 1440px) {
      top: 370px;
      left: 151px;
      width: 153px;
      height: 107px;
    }
  }
  .mixch {
    position: absolute;
    top: 25vw;
    left: 0vw;
    z-index: 2;
    display: inline-block;
    width: 10.5vw;
    height: 9vw;
    @media (min-width: 1440px) {
      top: 370px;
      left: 3px;
      width: 148px;
      height: 107px;
    }
  }
  .reality {
    position: absolute;
    top: 32vw;
    left: 5vw;
    z-index: 3;
    display: inline-block;
    width: 11vw;
    height: 8vw;
    @media (min-width: 1440px) {
      top: 467px;
      left: 75px;
      width: 153px;
      height: 102px;
    }
  }
`;

export const twitterBlock = css`
  margin-top: 60px;

  .twitter-timeline {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const imageMapTitle = css`
  margin-top: 80px;
  margin-bottom: -20px;
  @media (max-width: 767px) {
    margin-top: 50px;
    margin-bottom: -10px;
  }
`;

export const imageMapSec = css`
  position: relative;
`;

export const comingSoon = css`
  font-size: 1.45em;
  font-family: 'Montserrat', serif;
  padding: 35px 0 35px;

  &.c-white {
    color: #fff;
  }
`;

export const andMore = css`
  font-family: 'Montserrat', serif;
  font-size: 1.8em;
  margin-top: 60px;

  @media (max-width: 767px) {
    margin-top: 40px;
  }
`;

export const salesStart = css`
  ${scheduleDl}

  margin-top: 60px;

  @media (max-width: 767px) {
    margin-top: 40px;
  }
`;

export const youtubeBlock = css`
  margin: 60px auto 20px;
  max-width: 500px;
  width: 100%;

  .youtube {
    width: 100%;
    display: block;
    text-align: center;
    padding: 0 10px;
    .platform-title {
      font-size: 1em;
      line-height: 1.35;
      letter-spacing: 0.05em;
      font-weight: 700;
      color: #fff;
      margin: 0 0 15px;
    }
    .image-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      height: 130px;
      border-radius: 7px;
      img {
        max-width: 100%;
        width: auto;
        height: 42px;
      }
    }
  }

  @media (min-width: 768px) {
    a {
      .platform-logo,
      .gatsby-image-wrapper {
        transition: all 0.3s ease;
      }
    }

    a:hover {
      .platform-logo,
      .gatsby-image-wrapper {
        transform: scale(1.08);
      }
    }
  }

  @media (max-width: 767px) {
    .youtube {
      padding: 0 5px;
      .platform-title {
        font-size: 0.9em;
        margin: 0 0 10px;
      }
      .image-wrap {
        height: auto;
        padding: 26px 0;
        width: 100%;
        box-sizing: border-box;
        img {
          height: 38px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin-top: 40px;
  }
`;

export const modalWindowStyle = css`
  text-align: center;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  }
  .modal-window_button {
    color: #0000ee;
    text-decoration: underline;
    font-size: 0.875em;
    margin-top: 1rem;
    display: inline-block;

    &:hover {
      color: #551a8b;
    }
  }
  .modal-window_wrapper {
    text-align: left;

    small {
      font-size: 0.75em;
      margin-top: 0.25rem;
    }

    a {
      font-size: 0.75em;
      margin-top: 0.5rem;
      display: inline-block;
    }
  }
`;

export const archiveBlock = css`
  margin: 60px auto 20px;
  max-width: 500px;
  width: 100%;

  ul {
    display: grid;
    gap: 30px;
  }

  li {
    width: 100%;
    display: block;
    text-align: center;
  }

  a {
    display: block;
    background: #fff;
    border-radius: 7px;
    padding: 1.5em 10px;
    text-decoration: none;
  }

  .image-wrap {
    transition: 0.3s ease;
    img {
      max-width: 100%;
      width: auto;
    }
  }

  .archive-name {
    font-size: 1.1em;
    line-height: 1.5;
    color: #3b3b3b;
    font-weight: 700;
    display: block;
    margin-top: 1.25em;
  }

  @media (min-width: 768px) {
    a:hover {
      .image-wrap {
        transform: scale(1.1);
      }
    }
  }
`;
